<template>
  <el-row :key="$i18n.locale" v-loading="isLoading">
    <high-charts
      ref="ss-abw-graph"
      :options="chartOptions"
      constructor-type="stockChart"
    ></high-charts>
  </el-row>
</template>

<script>
import { mapGetters } from "vuex";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import highChartsTranslationMixin from "@/mixins/highChartsTranslationMixin";
import { ssABWDistributionGraph } from "./chartOptions";
export default {
  mixins: [datesHandlerMixin, userPreferenceMixin, highChartsTranslationMixin],
  data: function () {
    return {
      chartOptions: {
        ...ssABWDistributionGraph
      }
    };
  },
  watch: {
    getSSABWGraphDate() {
      this.hctm__initChartSeries();
    }
  },
  computed: {
    ...mapGetters("user", {
      getPreferredUnits: "getPreferredUnits"
    }),
    ...mapGetters("pondDetails/pondABWTab", {
      getDateValueFormat: "getDateValueFormat",
      getDateToAbwDistribution: "getDateToAbwDistribution",
      getSSABWGraphDate: "getSSABWGraphDate",
      getLoadingComponents: "getLoadingComponents"
    }),
    isLoading() {
      return ["FETCH_SS_DETAILS", "FETCH_SS_IMAGE_DETAILS"].some((x) =>
        this.getLoadingComponents.has(x)
      );
    },
    getSelectedDateInDateObj() {
      return this.dhm__dateUtilsLib.parse(
        this.getSSABWGraphDate,
        this.getDateValueFormat,
        new Date()
      );
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.hctm__initAxisTextKeys(
        "shrimpsnap.abw_range_with_units",
        "shrimpsnap.samples_with_units"
      );
      this.hctm__setAxisText();
      this.hctm__initChartSeries();
      this.rcm__registerResizeObserver("ss-abw-graph", () => {
        if (this.$refs["ss-abw-graph"]) {
          this.$refs["ss-abw-graph"].chart.reflow();
        }
      });
    });
  },
  beforeDestroy() {
    this.rcm__unregisterResizeObserver("ss-abw-graph", () => {
      if (this.$refs["ss-abw-graph"]) {
        this.$refs["ss-abw-graph"].chart.reflow();
      }
    });
  },
  methods: {
    hctm__initChartSeries() {
      const chartData =
        this.getDateToAbwDistribution[
          this.dhm__dateUtilsLib.formatDate(
            this.getSelectedDateInDateObj,
            this.dhm__dateUtilsLib.isoFormatString
          )
        ] || [];
      if (chartData.length === 0) {
        this.chartOptions.series = [];
      }
      this.$nextTick(() => {
        this.chartOptions.series = [
          {
            name: {
              xAxis: this.ftm__capitalize(
                this.$t("shrimpsnap.abw_range_with_units")
              ),
              yAxis: this.ftm__capitalize(
                this.$t("shrimpsnap.samples_with_units")
              )
            },
            type: "column",
            pointWidth: 30,
            data: chartData
          }
        ];
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
